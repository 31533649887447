import './AppRoutes.scss';
import {Navigate, Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import React, {lazy, Suspense} from 'react';
import Home from '../components/Home/Home';
import NavBar from '../components/NavBar/NavBar';
import {Box} from '@mui/material';
import {Loader} from '../components/Loader/Loader';
import Protected from '../components/Protected/Protected';
import NoDevTools from '../components/NoDevTools/NoDevTools';
import Forbidden from '../components/NoDevTools/Restricted/Forbidden';
import RoutingWrapper from './RoutingWrapper';
import RoomOpen from '../components/Room/RoomOpen/RoomOpen';
import Error from '../components/Error/Error';

const Panel = lazy(() => import('../components/Panel/Panel'));

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <NavBar/>
      <Box className="app-body">
        <Routes>
          <Route path={'/panel'}
                 element={<Protected component={<RoutingWrapper component={<Suspense fallback={<Loader/>}><Panel/></Suspense>}/>}/>}/>
          <Route path={'/room/:id'}
                 element={<RoutingWrapper component={<NoDevTools component={<RoomOpen/>}/>}/>}/>
          <Route exact path={'/'} element={<Home/>}/>
          <Route path={'/restricted/forbidden'} element={<Forbidden/>}/>
          <Route path={'/*'} element={<Error/>}/>
        </Routes>
      </Box>
    </BrowserRouter>
  );
}
