import {configureStore} from '@reduxjs/toolkit';
import CommonReducer from '../reducers/common/CommonReducer';
import I18nReducer from '../reducers/i18n/I18nReducer';
import PanelReducer from '../reducers/panel/PanelReducer';
import RoomReducer from '../reducers/room/RoomReducer';
import AuthReducer from '../reducers/auth/AuthReducer';

const rootReducer = {
  auth: AuthReducer,
  common: CommonReducer,
  i18n: I18nReducer,
  panel: PanelReducer,
  room: RoomReducer
};

export const store = configureStore({
  reducer: rootReducer
});
