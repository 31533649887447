import axios from 'axios';
import {panelLoaded, panelLoadingFailed, panelLoadingStart, panelRefreshStart, roomsLoaded, roomsLoadStart} from './PanelReducer';
import {commonToastShow} from '../common/CommonReducer';

export function panelStartLoading() {
  return async dispatch => {
    dispatch(panelLoadingStart());
    loadDashboard(dispatch);
  };
}

export function panelRefresh() {
  return async dispatch => {
    dispatch(panelRefreshStart());
    loadDashboard(dispatch);
  };
}

export function panelLoadRooms(page, size, active = true) {
  return async dispatch => {
    dispatch(roomsLoadStart());
    loadRooms(dispatch, page, size, active);
  };
}

function loadRooms(dispatch, page, size, active = true) {
  axios.get(`/api/panel/room?page=${page}&size=${size}&active=${active}`).then(
    success => dispatch(roomsLoaded(success.data)),
    fail => {
      dispatch(panelLoadingFailed(fail));
      dispatch(commonToastShow('error', fail));
    }
  );
}

function loadDashboard(dispatch) {
  axios.get(`/api/panel`).then(
    success => dispatch(panelLoaded(success.data)),
    fail => {
      dispatch(panelLoadingFailed(fail));
      dispatch(commonToastShow('error', fail));
    }
  );
}
