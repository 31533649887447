import {createSlice} from '@reduxjs/toolkit';
import {dialogDefaultAction} from './CommonDialog';
import {toast} from 'react-toastify';

export const commonDialogCfg = {
  dialogComponent: null,
  dialogStack: [],
  dialogCallback: () => null,
  dialogData: {
    title: null,
    content: null,
    actions: [dialogDefaultAction],
    options: {
      overridable: true,
      override: false,
      // list of props which should be concatenated with a space when currently opened dialog and should be shown
      // toast. If this is an empty array dialog will be put to the stack or follow override instructions
      fallWithProps: []
    }
  },
  toastStack: [],
  passwords: [],
  displayedName: null,
  devTools: false
};

const initialState = {
  initialized: false,
  dialogOpen: false
};

const commonReducer = createSlice({
  name: 'common',
  initialState,
  reducers: {
    commonDialogClose: state => {
      if (commonDialogCfg.dialogStack.length) {
        commonDialogCfg.dialogData = commonDialogCfg.dialogStack[0];
        commonDialogCfg.dialogStack = commonDialogCfg.dialogStack.slice(1);
      } else {
        state.dialogOpen = false;
      }
    },
    commonDialogOpen: (state, action) => {
      if (!state.dialogOpen
        || (commonDialogCfg.dialogData.options?.overridable // case if dialog can't be overridden
          && action.payload.options && action.payload.options.override)) { // case if should be overridden
        state.dialogOpen = true;
        commonDialogCfg.dialogData = action.payload;
      } else {
        commonDialogCfg.dialogStack = Object.assign([action.payload], commonDialogCfg.dialogStack);
      }
      commonDialogCfg.dialogComponent && commonDialogCfg.dialogComponent.forceUpdate();
    },
    commonToastShow: (state, action) => {
      toast[action.payload.level](action.payload.message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    },
    commonConfigLoaded: () => {
    },
    commonConfigSet: (state, action) => ({
      ...state,
      passwords: action.payload.passwords,
      displayedName: action.payload.displayedName,
      initialized: true
    }),
    commonDevToolsSet: (state, action) => {
      state.devTools = action.payload;
    }
  }
});
export const {
  commonDialogClose,
  commonDialogOpen,
  commonToastShow,
  commonConfigLoaded,
  commonConfigSet,
  commonDevToolsSet
} = commonReducer.actions;
export default commonReducer.reducer;
