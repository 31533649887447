import './App.css';
import 'react-toastify/dist/ReactToastify.min.css'
import React, {Component} from 'react';
import {sendError} from './services/ErrorService';
import {initLanguage, setLoadedLocale} from './reducers/i18n/I18nActions';
import {handleProvidedConfig, requestConfig, setDevToolsActive} from './reducers/common/CommonActions';
import {connect} from 'react-redux';
import CommonDialog from './reducers/common/CommonDialog';
import {getLocale} from './utils/CommonUtils';
import AppRoutes from './route/AppRoutes';
import {detectDevToolsStart, onResizeExecute, onUnloadExecute} from './utils/BrowserUtils';
import {AppBar, Toolbar} from '@mui/material';
import {ToastContainer} from 'react-toastify';

export const devTools = {
  fcn: null,
  active: false
};

if (process.env.NODE_ENV === 'production') {
  detectDevToolsStart((active, reason) => {
    console.log('Dev tools: ', active);
    devTools.active = active;
    devTools.fcn && devTools.fcn(active, reason);
  });
}

class App extends Component {

  state = {
    loading: true
  };

  componentDidMount() {
    devTools.fcn = this.props.setDevTools;
    window.addEventListener('error', this.onErrorHandler);
    window.addEventListener('unload', onUnloadExecute);
    window.addEventListener('resize', onResizeExecute);
    this.initConfig();
    this.configureLocale();
    this.removePreloader();
  }

  onErrorHandler = (args) => {
    const {message, error} = args;
    const stack = error ? error.stack.replace(/(^"\s*|[\r\n]\s*|\s*"$)/g, ' ') : '';
    sendError(`${message}; stack: ${stack}`);
  };

  initConfig = () => {
    console.log('initConfig');
    if (window.config) {
      this.props.setConfig(window.config);
    } else {
      this.props.loadConfig();
    }
  };

  configureLocale = () => {
    const locale = getLocale();
    if (window.translation) {
      const keys = JSON.parse(window.translation);
      if (keys.locale === locale.key) {
        this.props.setLanguage(keys, locale);
      } else {
        this.props.initLanguage(locale);
      }
    } else {
      this.props.initLanguage(locale);
    }
  };

  removePreloader = () => {
    const pl = document.getElementById('pl');
    const ps = document.getElementById('ps');
    if (pl) {
      pl.remove();
      ps.remove();
      this.setState({loading: false});
    }
  };

  render() {
    return (
      <div className="App">
        <AppBar className="invisible" position="relative"><Toolbar/></AppBar>
        <AppRoutes/>
        {this.props.isDialog ? <CommonDialog/> : ''}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isDialog: state.common.dialogOpen
  };
};

const mapActionsToProps = dispatch => ({
  initLanguage: language => dispatch(initLanguage(language)),
  setLanguage: (translation, language) => dispatch(setLoadedLocale(translation, language)),
  loadConfig: () => dispatch(requestConfig()),
  setConfig: config => dispatch(handleProvidedConfig(config)),
  setDevTools: (active, reason) => dispatch(setDevToolsActive(active, reason))
});

export default connect(mapStateToProps, mapActionsToProps)(App);
