import './Error.scss';
import TranslatableComponent from '../../utils/TranslatableComponent';
import {Button, Typography} from '@mui/material';
import {translate} from '../../reducers/i18n/I18nReducer';

class Error extends TranslatableComponent {

  state = {
    error: window.error
  };

  redirectBack() {
    window.location = '/';
  }

  render() {
    return (
      <div className="container">
        <div className="gears">
          <div className="table-group blinker">
            {this.state.error ?
              this.state.error.status && (this.state.error.status + '').split('').map(num => {
                if (num !== '0') {
                  return <div className="table-col error-text">{num}</div>;
                } else {
                  return <>
                    <div className="table-col">
                      <div className="gear two">
                        <div className="bar"/>
                        <div className="bar"/>
                        <div className="bar"/>
                      </div>
                    </div>
                  </>;
                }
              }) : <Typography variant={'h4'}>{translate('error.common')}</Typography>}
          </div>
          <div className="text-container">
            <Typography className={'error-message'} variant={'h3'}>{this.state.error?.error}</Typography>
          </div>
          <Button variant="contained" onClick={this.redirectBack.bind(this)}>{translate('error.back')}</Button>
        </div>
      </div>
    );
  }
}

export default Error;
