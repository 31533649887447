import './Loader.scss';
import {Component} from 'react';

export class Loader extends Component {
  render() {
    return <div className={"loader-ring-wrap" + (this.props.dark ? ' dark' : '')}>
      <div className="loader-ring">Loading
        <span></span>
      </div>
    </div>;
  }
}