import {translate} from '../../../reducers/i18n/I18nReducer';
import TranslatableComponent from '../../../utils/TranslatableComponent';
import {Box, Button, Typography} from '@mui/material';

class Forbidden extends TranslatableComponent {

  state = {
    from: new URLSearchParams(window.location.search).get('from')
  };

  redirectBack() {
    window.location = this.state.from;
  }

  render() {
    return (
      <Box textAlign={'center'} marginTop="20%">
        <Typography>{translate('devTools.notAllowed')}</Typography>
        <Typography>{translate('devTools.closeMsg')}</Typography>
        {this.state.from ?
          <Button variant="contained" onClick={this.redirectBack.bind(this)}>{translate('devTools.backButton')}</Button>
          : <></>}
      </Box>
    );
  }
}

export default Forbidden;
