import './CommonDialog.scss';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {dialogClose} from './CommonActions';
import {commonDialogCfg} from './CommonReducer';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import type {DialogAction} from '../../model/DialogAction';

export const dialogDefaultAction: DialogAction = {
  name: 'OK',
  close: true,
  action: null
};

class CommonDialog extends Component {
  initialState = {
    dialogOpen: false,
    title: '',
    content: '',
    actions: [dialogDefaultAction],
    config: {}
  };

  state = {
    dialogOpen: false,
    title: '',
    content: '',
    actions: [dialogDefaultAction],
    config: {}
  };

  componentDidMount() {
    commonDialogCfg.dialogComponent = this;
    const data = commonDialogCfg.dialogData;
    this.setState(data ? data : this.initialState);
  };

  componentWillUnmount() {
    commonDialogCfg.dialogComponent = null;
  }

  action = (act) => {
    return () => {
      act.action && act.action();
      if (act.close) {
        this.props.close();
      }
    };
  };

  render() {
    return (
      <Dialog onClose={this.props.close}
              aria-labelledby="common-dialog-title"
              disableScrollLock={true}
              open={this.props.dialogOpen}>
        {commonDialogCfg.dialogData.title ?
          <DialogTitle id="common-dialog-title">{commonDialogCfg.dialogData.title}</DialogTitle>
          : <></>}
        <DialogContent>
          <Grid container direction="column" className="common-dialog-content">
            {commonDialogCfg.dialogData.content}
            {commonDialogCfg.dialogData.actions?.length ?
              <DialogActions>
                {commonDialogCfg.dialogData.actions.map((act, index) =>
                  <Button variant="contained" color="primary" onClick={this.action(act)} key={index}>
                    {act.name}
                  </Button>
                )}
              </DialogActions>
              : <></>}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    dialogOpen: state.common.dialogOpen
  };
};

const mapActionsToProps = dispatch => ({
  close: () => dispatch(dialogClose())
});


export default connect(mapStateToProps, mapActionsToProps)(CommonDialog);