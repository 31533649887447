import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  authorized: false,
  user: null,
  errMsg: null,
  authProgress: false
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: state => {
      state.authProgress = true;
      state.errMsg = null;
    },
    loginFailure: (state, action) => {
      state.authProgress = false;
      state.errMsg = action.payload.err;
    },
    loginSuccess: (state, action) => {
      state.authProgress = false;
      state.user = action.payload.user;
      state.authorized = !!action.payload.user;
      state.errMsg = null;
    },
    logout: state => {
      state.authProgress = true;
    },
    logoutDone: () => {
      return {...initialState};
    }
  }
});

export const {login, loginFailure, loginSuccess, logout, logoutDone} = authReducer.actions;
export default authReducer.reducer;
