import {Component} from 'react';
import {translationConnect, translationDisconnect} from '../reducers/i18n/I18nReducer';

export default class TranslatableComponent extends Component {

  componentDidMount() {
    translationConnect(this);
  }

  componentWillUnmount() {
    translationDisconnect(this);
  }
}
