import {parseResponse} from '../../utils/ResponseUtils';
import * as Lang from './Lang';
import {changeLocale} from '../../services/ConfigService';
import {languageChange, languageLoaded} from './I18nReducer';

export function initLanguage(language) {
  return changeLanguage(language, false);
}

export function changeLanguage(lang, updateBackend = true) {
  return async dispatch => {
    if (Lang.AVAILABLE.indexOf(lang) === -1) {
      return;
    }

    dispatch(languageChange(lang));

    fetch(`/locales/${lang.key}/translation.json`).then(parseResponse).then(handleLoadedLocale(dispatch));

    if (updateBackend) await changeLocale(lang.key);
  };
}

function handleLoadedLocale(dispatch) {
  return response => dispatch(languageLoaded({keys: response}));
}

export function setLoadedLocale(keys, requested) {
  return async dispatch => {
    dispatch(languageLoaded({keys, requested}));
  };
}
