import {Component} from "react";
import {Timer} from "@mui/icons-material";

import "./Home.scss"
import {useLocation, useParams} from "react-router-dom";

class Home extends Component {

    render() {
        return (
            <div className={'interviewer-home'}>
                <div className="container">
                    <h1>
                        Application
                        <br/>
                        Coming Soon
                    </h1>
                    <Timer/>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);

export function withRouter(Child) {
    return (props) => {
        const location = useLocation();
        const params = useParams();
        return <Child {...props} params={params} location={location}/>;
    };
}
