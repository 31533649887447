import axios from 'axios';
import {dialogSimpleShow} from '../common/CommonActions';
import {login, loginFailure, loginSuccess, logout, logoutDone} from './AuthReducer';

export function runLoginRequest(data) {
  return async dispatch => {
    dispatch(login());

    axios.post('/api/auth/login', data).then(
      success => {
        dispatch(loginSuccess({user: success.data?.session?.user}));
      },
      fail => {
        dispatch(loginFailure({err: fail.response.data.errors}));
      }
    );
  };
}

export function runLogoutRequest() {
  return async dispatch => {
    dispatch(logout());
    axios.post(`/api/auth/logout`).then(
      () => dispatch(logoutDone()),
      failure => dialogSimpleShow(failure)
    );
  };
}
