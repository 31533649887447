import React from 'react';
import {connect} from 'react-redux';
import './NavBar.scss';
import {isSubdomain} from '../../utils/CommonUtils';
import {changeLanguage} from '../../reducers/i18n/I18nActions';
import {AVAILABLE} from '../../reducers/i18n/Lang';
import {dialogClose, dialogShow} from '../../reducers/common/CommonActions';
import {NavLink} from 'react-router-dom';
import Login from '../Login/Login';
import {runLogoutRequest} from '../../reducers/auth/AuthActions';
import {translate} from '../../reducers/i18n/I18nReducer';
import TranslatableComponent from '../../utils/TranslatableComponent';
import {onResizeSubscribe, RESIZE_NAME_NAVBAR} from '../../utils/BrowserUtils';
import {AppBar, Grid, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import {Language, MenuRounded} from '@mui/icons-material';

const NAV_BAR_TYPE_FIXED = 'NAV_BAR_TYPE_FIXED';
const NAV_BAR_TYPE_BLOCK = 'NAV_BAR_TYPE_BLOCK';


class NavBar extends TranslatableComponent {

  state = {
    anchorEl: null,
    displayedMenuItems: -1,
    showMenuButton: true,
    isSubDomain: isSubdomain()
  };

  buildLoginDialog = (dialog) => {
    return () => {
      dialog('', <Login successCallback={() => this.props.closeDialog()}/>, [
        {
          name: translate('cancel'),
          close: true
        }
      ]);
    };
  };

  buildLogoutDialog = (dialog) => {
    return () => {
      dialog(translate('auth.logoutQuestion'), null, [
        {
          name: translate('confirm'),
          action: this.props.logoutAction,
          close: true
        },
        {
          name: translate('cancel'),
          close: true
        }
      ]);
    };
  };

  handleLocaleChange = () => {
    const selection = AVAILABLE.find(lang => lang.key !== this.props.locale);
    this.props.changeLocale(selection);
  };

  menuEntries = [
    {
      id: -2,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.login',
      page: null,
      condition: () => !this.props.auth.authorized,
      lr: false,
      act: this.buildLoginDialog(this.props.showDialog)
    },
    {
      id: -1,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.panel',
      page: '/panel',
      condition: () => this.props.auth.authorized,
      lr: false,
      act: null
    },
    {
      id: 0,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.logout',
      page: null,
      condition: () => this.props.auth.authorized,
      lr: false,
      act: this.buildLogoutDialog(this.props.showDialog)
    },
    {
      id: 1000001,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.about',
      page: '/about',
      condition: () => true,
      lr: false,
      act: null
    },
    {
      id: 1000002,
      type: NAV_BAR_TYPE_BLOCK,
      title: () => <>
        <Language className='nav-bar-icon'/>
        {AVAILABLE.find(lang => lang.key !== this.props.locale).key.toUpperCase()}
      </>,
      page: null,
      condition: () => true,
      lr: false,
      act: () => this.handleLocaleChange()
    }
  ];

  handleShowMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  hideMenu = () => {
    this.setState({anchorEl: null});
  };

  hideMenuAction = action => {
    return () => {
      this.hideMenu();
      if (action) action();
    };
  };

  componentDidMount() {
    super.componentDidMount();
    this.updateWidth();
    onResizeSubscribe(RESIZE_NAME_NAVBAR, () => this.updateWidth());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const isI18nLoaded = this.props.i18nLoaded;
    if (isI18nLoaded !== prevProps.i18nLoaded) {
      this.updateWidth();
    }
  }

  updateWidth = () => {
    if (window.innerWidth < 350) {
      this.setState({displayedMenuItems: 0, showMenuButton: true});
      return;
    }
    const containerWidth = document.querySelector('.app-bar-container').clientWidth;
    const calculator = document.querySelector('.app-bar-text-length-calculator');
    const menuButton = document.querySelector('.app-bar-menu-button');
    let width = menuButton ? menuButton.clientWidth : 0;
    let num = 0;
    const show = this.menuEntries.filter(entry => entry.lr);
    for (const el in show) {
      if (!show.hasOwnProperty(el)) continue;
      const entry = show[el];
      calculator.innerHTML = entry.type === NAV_BAR_TYPE_FIXED ? translate(entry.title) : 'icon';
      const w = calculator.clientWidth + 40;
      if (w + width < containerWidth) {
        width += w;
        num++;
        continue;
      }
      break;
    }
    const all = this.menuEntries.filter(entry => entry.lr).length;
    this.setState({displayedMenuItems: num, showMenuButton: all > num});
  };

  printMenuItem = (isHidden, entry) => {
    const idPrefix = isHidden ? 'b' : '';
    const itemClass = isHidden ? 'app-bar-menu-item-hidden' : 'app-bar-menu-item';
    const clickFcn = isHidden ? this.hideMenuAction(entry.act) : entry.act;
    if (NAV_BAR_TYPE_BLOCK === entry.type) {
      return <MenuItem
        key={idPrefix + entry.id}
        className={itemClass}
        onClick={clickFcn}
      >{entry.title(isHidden)}</MenuItem>;
    }
    const title = NAV_BAR_TYPE_FIXED === entry.type ? translate(entry.title) : entry.title;
    return entry.page
      ? <MenuItem
        key={idPrefix + entry.id}
        className={itemClass}
        component={NavLink}
        onClick={clickFcn}
        to={entry.page}
      >{title}</MenuItem>
      : <MenuItem
        key={idPrefix + entry.id}
        className={itemClass}
        onClick={clickFcn}
      >{title}</MenuItem>;
  };

  printMenuItems = isHidden => {
    const showAll = this.state.displayedMenuItems < 0;
    return this.menuEntries
      .map(entry => {
        entry.lr = false;
        return entry;
      })
      .filter(entry => entry.condition())
      .map((entry, index) => {
          entry.lr = true;
          if (showAll || (isHidden && (index >= this.state.displayedMenuItems))) {
            return this.printMenuItem(isHidden, entry);
          }
          if (showAll || (!isHidden && (index < this.state.displayedMenuItems))) {
            return this.printMenuItem(isHidden, entry);
          }
          return '';
        }
      );
  };

  render() {
    const {anchorEl} = this.state;
    return (
      <AppBar className="app-bar-fixed">
        <MenuItem className="app-bar-text-length-calculator invisible"/>
        <Toolbar>
          <Grid item xs={4} container justifyContent={'flex-start'}>
            <Typography className="app-bar-title" color="inherit" component={NavLink} to="/">{translate('name')}</Typography>
          </Grid>

          <Grid item xs={8} flexGrow={1}>
            <Grid container justifyContent="flex-end" className="app-bar-container">
              {this.printMenuItems(false)}
              {this.state.showMenuButton ? [
                <IconButton color="inherit"
                            key="app-bar-menu-icon"
                            className="app-bar-menu-button"
                            aria-owns={anchorEl ? 'app-bar-menu-button' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleShowMenu}>
                  <MenuRounded/>
                </IconButton>,
                <Menu id="app-bar-menu-button"
                      key="app-bar-menu-content"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.hideMenu}>
                  {this.printMenuItems(true)}
                </Menu>
              ] : ''}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    common: state.common,
    i18nLoaded: state.i18n.initialized,
    locale: state.i18n.current?.key,
    isRu: state.i18n.isRu,
    isEn: state.i18n.isEn,
    auth: state.auth
  };
};

const mapActionsToProps = dispatch => ({
  showDialog: (title, body, actions) => dispatch(dialogShow(title, body, actions)),
  closeDialog: () => dispatch(dialogClose()),
  changeLocale: language => dispatch(changeLanguage(language)),
  logoutAction: () => dispatch(runLogoutRequest())
});

export default connect(mapStateToProps, mapActionsToProps)(NavBar);
