import {useNavigate} from 'react-router';
import {useEffect} from 'react';

export default function RoutingWrapper(props) {

  const navigate = useNavigate();

  useEffect(() => {
    redirect = path => navigate(path);
  });

  return props.component;
}

export var redirect = () => {
  console.error('Redirections not initialized');
};