const unload = {};

export const ON_UNLOAD_NAME_ROOM = 'room';

export function onUnloadSubscribe(name, fcn) {
  unload[name] = fcn;
}

export function onUnloadUnsubscribe(name) {
  delete unload[name];
}

export function onUnloadExecute() {
  Object.values(unload).forEach(fcn => fcn());
}

const resize = {};

export const RESIZE_NAME_BLOCK = 'block';
export const RESIZE_NAME_NAVBAR = 'navbar';

export function onResizeSubscribe(name, fcn) {
  resize[name] = fcn;
}

export function onResizeUnsubscribe(name) {
  delete resize[name];
}

export function onResizeExecute() {
  Object.values(resize).forEach(fcn => fcn());
}

const dts = {
  listener: null,
  isOpened: false,
  isTriggered: false,
  reason: null,
  monitoring: null,
  threshold: 150
};

export function detectDevToolsStart(listener) {
  if (dts.monitoring !== null) {
    console.warn('Monitoring already started');
    return;
  }
  dts.listener = listener;
  dts.monitoring = setInterval(devToolsCheck, 500);
  console.log('DevTool monitoring started');
}

export function detectDevToolsStop() {
  if (dts.monitoring === null) {
    console.warn('Monitoring already stopped');
    return;
  }
  clearInterval(dts.monitoring);
  dts.listener = null;
}

function devToolsCheck() {
  // https://github.com/dsa28s/detect-browser-devtools/blob/master/src/devtools/DevToolsManager.ts

  // firebug
  if (window.Firebug && window.Firebug.chrome && window.Firebug.chrome.isInitialized) {
    dts.isOpened = true;
    dts.isTriggered = true;
    dts.reason = 'firebug';
  }

  if (!dts.isTriggered) {
    const devtools = /./;
    devtools.toString = () => {
      dts.isOpened = true;
      dts.isTriggered = true;
      dts.reason = 'console';
    };
    console.log('%c', devtools);
  }

  if (!dts.isTriggered) {
    // Chrome
    if (console.profiles !== undefined && console.profiles !== null) {
      console.profile();
      console.profileEnd();

      if (console.profiles.length > 0) {
        dts.isOpened = true;
        dts.isTriggered = true;
        dts.reason = 'profiles';
      }
    }
  }

  if (!dts.isTriggered) {
    const wt = window.outerWidth - window.innerWidth > dts.threshold;
    const ht = window.outerHeight - window.innerHeight > dts.threshold;
    if (wt || ht) {
      dts.isOpened = true;
      dts.reason = 'wt&ht';
    }
  }

  if (dts.isDevtoolOpened !== dts.isOpened) {
    dts.isDevtoolOpened = dts.isOpened;
    dts.listener(dts.isDevtoolOpened, dts.reason);
  }
}
