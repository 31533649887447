import {optionalInsert} from './StringUtils';
import Cookies from 'js-cookie';
import {AVAILABLE, EN} from '../reducers/i18n/Lang';
import {store} from '../store';

export function isLocalhost() {
  return window.location.hostname === 'localhost';
}

export function getFirstLevelDomain() {
  return (isLocalhost() ? 'localhost' : window.location.hostname.match(/[^.]*\.[^.]*$/)[0])
    + optionalInsert(window.location.port, `:${window.location.port}`);
}

export function findUserById(id) {
  return store.getState().common.users?.find(user => user.id === id) ?? '';
}

export function getBrowserLocale() {
  return navigator.language.match(/^(\w{2}).*/)[1];
}

export function getLocale() {
  const locale = Cookies.get('interviewer_lang') ?? getBrowserLocale();
  return AVAILABLE.find(lang => lang.key === locale) ?? EN;
}

export function isSubdomain() {
  if (isLocalhost()) {
    return false;
  }
  const hostname = window.location.hostname;
  const firstDot = hostname.indexOf('.');
  const isSubdomain = hostname.indexOf('.', firstDot + 1);
  return isSubdomain !== -1;
}

export function deepEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2))
      || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

