import {Component} from 'react';
import {connect} from 'react-redux';

class NoDevTools extends Component {

  render() {
    //return (this.props.common.devTools ? window.location = `/restricted/forbidden?from=${window.location.pathname}` : this.props.component);
    return this.props.component;
  }
}

const mapStateToProps = state => {
  return {
    common: state.common
  };
};

export default connect(mapStateToProps)(NoDevTools);
