import './RoomOpen.scss';
import React, {Component, lazy, Suspense} from 'react';
import {roomInit, roomLoad} from '../../../reducers/room/RoomActions';
import {connect} from 'react-redux';
import {Loader} from '../../Loader/Loader';

const Room = lazy(() => import('../Room'));
const RoomAuth = lazy(() => import('../RoomAuth/RoomAuth'));

class RoomOpen extends Component {

  initState = {
    roomId: window.location.pathname.match(/\/room\/(\w+).*/)[1],
    password: new URLSearchParams(window.location.search).get('pw'),
    reconnectFunction: null
  };

  state = {...this.initState};

  componentDidMount() {
    this.props.initRoom(this.state.roomId, this.state.password);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const userChanged = prevProps.common.initialized && prevProps.auth.user?.username !== this.props.auth.user?.username;
    const roomChanged = !(!prevProps.room.initialized || !this.props.room.initialized)
      && prevProps.room.details?.roomKey !== this.props.room.details?.roomKey;
    //console.info('User change: ', userChanged, 'Room change:', roomChanged, prevProps.auth.user, this.props.auth.user);
    if (roomChanged || userChanged || this.props.room.reconnect) {
      this.props.initRoomAsync(this.state.roomId, this.state.password);
    }
  }

  render() {
    return (
      <div className={'room-wrapper'}>
        <div className={'room'}>
          {this.props.room.loading ? <Loader/> : (
            !!this.props.room.details
              ? <Suspense fallback={<Loader/>}><Room/></Suspense>
              : <Suspense fallback={<Loader/>}><RoomAuth roomId={this.state.roomId}/></Suspense>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    common: state.common,
    room: state.room,
    auth: state.auth
  };
};

const mapActionsToProps = dispatch => ({
  initRoom: (id, password) => dispatch(roomInit(id, password)),
  initRoomAsync: (id, password) => dispatch(roomLoad(id, password))
});

export default connect(mapStateToProps, mapActionsToProps)(RoomOpen);