import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment/moment';

const translator = (context) => {
  return function translate(key, args = []) {
    if (!key) return '';
    const parts = key && key.split('.');
    let property = context;
    parts.forEach(pt => property = property[pt] ? property[pt] : '');

    args && (Array.isArray(args) && args || [args]).forEach((arg, index) => {
      property = property.split(`%${index}`).join(arg);
    });

    return property;
  };
};

const initialState = {
  current: null,
  requested: null,
  initialized: false,
  keys: {}
};

const i18nReducer = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    languageChange: (state, action) => {
      state.requested = action.payload;
      translateComponents.forEach(component => component.forceUpdate());
    },
    languageLoaded: (state, action) => {
      const requested = state.requested ?? action.payload.requested;
      moment.locale(requested?.key);
      translate = translator(action.payload?.keys);
      translateComponents.forEach(component => component.forceUpdate());
      state.initialized = true;
      state.keys = action.payload.keys;
      state.current = requested;
      state.isRu = requested.key === 'ru';
      state.isEn = requested.key === 'en';
    }
  }
});

const translateComponents = [];
export const translationConnect = context => translateComponents.push(context);
export const translationDisconnect = context => translateComponents.splice(translateComponents.indexOf(context), 1);
export var translate = (key, args = []) => '...';
export const {languageChange, languageLoaded} = i18nReducer.actions;
export default i18nReducer.reducer;
