import axios from 'axios';
import {
  roomAuthDone,
  roomAuthFailed,
  roomAuthRequest,
  roomCreateDone,
  roomCreateFailed,
  roomCreateStart,
  roomOpenDone,
  roomOpenFailed,
  roomOpenStart
} from './RoomReducer';
import type {RoomAuthRequest} from '../../model/RoomAuthRequest';
import {roomDeleteFailed, roomDeleteStart} from '../panel/PanelReducer';
import {panelRefresh} from '../panel/PanelActions';
import {commonToastShow} from '../common/CommonReducer';

export function roomInit(roomId, password) {
  return dispatch => {
    if (window.roomAttempt && !window.room) {
      dispatch(roomOpenFailed());
    } else if (window.room) {
      dispatch(roomOpenDone(window.room));
    } else {
      dispatch(roomLoad(roomId, password));
    }
  };
}

export function roomLoad(id, password) {
  return async dispatch => {
    dispatch(roomOpenStart());
    axios.get(`/api/room/${id}`, {
      params: {password}
    }).then(
      success => {
        dispatch(roomOpenDone(success.data));
      },
      err => {
        dispatch(roomOpenFailed({err: err.response.data}));
      }
    );
  };
}

export function roomAuthenticate(request: RoomAuthRequest) {
  return async dispatch => {
    dispatch(roomAuthRequest());
    axios.post(`/api/room/${request.roomId}`, request).then(
      success => dispatch(roomAuthDone(success.data)),
      failure => dispatch(roomAuthFailed(failure.response.data))
    );
  };
}

export function roomCreate(title, type, password, callback) {
  return async dispatch => {
    dispatch(roomCreateStart());
    axios.post(`/api/room/create`, {title, type, password}).then(
      success => {
        dispatch(roomCreateDone({room: success.data}));
        if (callback) callback(success.data.room.roomKey);
      },
      err => {
        dispatch(roomCreateFailed({err: err.response.data}))
        dispatch(commonToastShow('error', err.response.data))
      }
    );
  };
}

export function roomDelete(id) {
  return async dispatch => {
    dispatch(roomDeleteStart());
    axios.delete(`/api/room/archive/${id}`).then(
      () => dispatch(panelRefresh()),
      err => {
        dispatch(commonToastShow('error', err.response.data));
        dispatch(roomDeleteFailed({err: err.response.data}));
      }
    );
  };
}
