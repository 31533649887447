import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  rooms: [],
  err: null
};
const panelReducer = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    panelLoadingStart: state => {
      state.loading = true;
      state.rooms = [];
    },
    panelLoaded: (state, action) => {
      state.loading = false;
      state.rooms = action.payload.rooms;
    },
    panelLoadingFailed: (state, action) => {
      state.loading = false;
      state.rooms = [];
      state.err = action.payload.err;
    },
    panelRefreshStart: state => {
      state.loading = true;
    },
    roomDeleteStart: state => {
      state.loading = true;
    },
    roomDeleteFailed: (state, action) => {
      state.loading = false;
      state.err = action.payload.err;
    },
    roomsLoadStart: state => {
      state.loading = true;
    },
    roomsLoaded: (state, action) => {
      state.loading = false;
      state.rooms = action.payload
    }
  }
});
export const {
  panelLoaded,
  panelLoadingFailed,
  panelLoadingStart,
  panelRefreshStart,
  roomDeleteStart,
  roomDeleteFailed,
  roomsLoadStart,
  roomsLoaded
} = panelReducer.actions;
export default panelReducer.reducer;
