import {load, send} from './BaseService';

export const changeLocale = (locale) => {
  return send('/api/config/locale', {lang: locale});
};

export const loadConfig = () => {
  return load('/api/config');
};

export const ping = () => {
  console.log("ping")
  return load('/api/config/ping');
};
