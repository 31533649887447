import React from 'react';

import './Login.scss';
import {Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography} from '@mui/material';
import {connect} from 'react-redux';
import {runLoginRequest} from '../../reducers/auth/AuthActions';
import {translate} from '../../reducers/i18n/I18nReducer';
import TranslatableComponent from '../../utils/TranslatableComponent';

class Login extends TranslatableComponent {

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidMount();
    if (this.props.successCallback && this.props.auth.authorized !== prevProps.auth.authorized) {
      this.props.successCallback();
    }
  }

  login(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    this.props.loginRequest(data);
  }

  render() {
    // todo: add preloaders
    return (
      <div className={'auth'}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography component="h1" variant="h5">
              {translate('auth.loginTitle')}
            </Typography>
            <Box component="form" onSubmit={evt => this.login(evt)} noValidate sx={{mt: 1}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label={translate('auth.usernameLabel')}
                name="username"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={translate('auth.passwordLabel')}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="true" color="primary" id="rememberMe" name="rememberMe" checked={true}/>}
                label={translate('auth.rememberMeLabel')}
              />
              {this.props.auth.errMsg && <p className={'auth-err'}>{this.props.auth.errMsg}</p>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
              >
                {translate('auth.submitButton')}
              </Button>
              {/*<Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>*/}
            </Box>
          </Box>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapActionsToProps = dispatch => ({
  loginRequest: data => dispatch(runLoginRequest(data))
});

export default connect(mapStateToProps, mapActionsToProps)(Login);
