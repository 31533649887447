import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  loading: true,
  details: null,
  err: null,
  authLoading: false,
  authErr: null,
  connect: false,
  reconnect: false,
  settings: false
};
const roomReducer = createSlice({
  name: 'room',
  initialState,
  reducers: {
    roomCreateStart: state => {
      state.loading = true;
      state.err = null;
    },
    roomCreateDone: (state, action) => {
      state.loading = false;
      state.role = action.payload.role;
      state.details = action.payload.room;
      state.err = null;
    },
    roomCreateFailed: (state, action) => {
      state.loading = false;
      state.err = action.payload.err;
    },
    roomOpenStart: state => {
      state.loading = true;
      state.err = null;
      state.connect = false;
    },
    roomOpenDone: (state, action) => {
      state.loading = false;
      state.role = action.payload.role;
      state.details = action.payload.room;
      state.privileged = action.payload.privileged;
      state.err = null;
      state.initialized = true;
      state.connect = true;
      state.reconnect = false;
    },
    roomOpenFailed: (state, action) => {
      state.err = action.payload?.err;
      state.loading = false;
      state.role = null;
      state.details = null;
      state.initialized = false;
      state.reconnect = false;
    },
    roomConnected: state => {
      state.loading = false;
    },
    roomConnectFailed: state => {
      state.loading = false;
    },
    roomExit: () => initialState,
    roomRevalidate: () => {
      return {...initialState, reconnect: true};
    },
    roomRevalidateFailed: () => {
      return {...initialState, reconnect: false};
    },
    roomAuthRequest: (state) => {
      state.authLoading = true;
      state.authErr = null;
    },
    roomAuthDone: (state, action) => {
      state.authLoading = false;
      state.role = action.payload.role;
      state.details = action.payload.room;
      state.privileged = action.payload.privileged;
      state.initialized = true;
      state.connect = true;
    },
    roomAuthFailed: (state, action) => {
      state.authLoading = false;
      state.authErr = action.payload;
    },
    roomAuthInvalidateErr: state => {
      state.authErr = null;
    },
    toggleSettings: state => {
      state.settings = !state.settings;
    },
    roomChangeTitle: (state, action) => {
      state.details.title = action.payload;
    }
  }
});
export const {
  roomCreateStart,
  roomCreateDone,
  roomCreateFailed,
  roomOpenStart,
  roomOpenDone,
  roomOpenFailed,
  roomExit,
  roomRevalidate,
  roomRevalidateFailed,
  roomConnected,
  roomConnectFailed,
  roomAuthRequest,
  roomAuthDone,
  roomAuthFailed,
  roomAuthInvalidateErr,
  toggleSettings,
  roomChangeTitle
} = roomReducer.actions;
export default roomReducer.reducer;
