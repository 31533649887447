import React, {Component} from 'react';
import {connect} from 'react-redux';
import Login from '../Login/Login';
import {Box} from '@mui/material';
import './Protected.scss';

class Protected extends Component {

  render() {
    return (
      this.props.auth.authorized ? this.props.component : (
        <Box className="protected-wrapper">
          <Login/>
        </Box>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Protected);

