import {loadConfig} from '../../services/ConfigService';
import {
  commonConfigLoaded,
  commonConfigSet,
  commonDevToolsSet,
  commonDialogClose,
  commonDialogOpen,
  commonToastShow
} from './CommonReducer';
import {loginSuccess} from '../auth/AuthReducer';
import {dialogDefaultAction} from './CommonDialog';

export function dialogSimpleShow(content) {
  return async dispatch => {
    dispatch(commonDialogOpen({title: null, content, actions: [dialogDefaultAction]}));
  };
}

export function dialogShow(title, content, actions = [dialogDefaultAction], config = {}) {
  return async dispatch => {
    dispatch(commonDialogOpen({title, content, actions, config}));
  };
}

export function dialogClose() {
  return async (dispatch) => {
    dispatch(commonDialogClose());
  };
}

export function requestConfig() {
  return async dispatch => {

    console.debug('loadingConfig');
    loadConfig().then(handleLoadedConfig(dispatch));

    dispatch(commonConfigLoaded());
  };
}

function handleLoadedConfig(dispatch) {
  return response => {
    if (response.data.user) {
      dispatch(loginSuccess({user: response.data.user}));
    }
    dispatch(commonConfigSet(response.data));
  };
}

export function handleProvidedConfig(config) {
  return dispatch => {
    if (config.user) {
      dispatch(loginSuccess({user: config.user}));
    }
    dispatch(commonConfigSet(config));
  };
}

export function toastShow(level, message) {
  return dispatch => {
    dispatch(commonToastShow({level, message}))
  }
}

export function setDevToolsActive(active, reason) {
  return async dispatch => {
    dispatch(commonDevToolsSet({active, reason}));
  };
}
